//Animation

//image overlay
.image {
  position: relative;
}
.overlay {
  background: rgba(0,0,0,0.55);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
}
.overlay-text {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 20%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  h3 {
    color: white !important;
    margin: 0 auto !important;
    text-transform: uppercase;
    font-size: 30px !important;
    letter-spacing: 1px;
  }
}
.image:hover .overlay {
  opacity: 1;
}
.image:hover .overlay-text {
  opacity: 1;
  top: 50%;
  left: 50%;
}
//Magnify glass image overlay
//overlay popout
.overlay-popout {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.image:hover .overlay-popout {
  opacity: 1;
  font-size: 60px;
  color: white;
}
.photo:hover .overlay-popout {
  opacity: 1;
  font-size: 60px;
  color: white;
}