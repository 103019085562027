//INTERIOR CSS
//.full-width-image {
//  .title {
//    h3, button {
//      display: none;
//    }
//  }
//}
.interior-content {
  margin-top: 40px;
  margin-bottom: 20px;
  min-height: 400px;
}

// PROPERTY PAGE
.property-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image {
    align-self: center;
    text-align: center;
    flex: 1;
    margin: 20px;
    .img-fluid {
      width: 100%;
    }
  }
  p.info {
    flex: 1;
    text-align: center;
    margin: 0 auto;
    font-size: 26px;
  }
  p {
    flex: 0 0 100%;
    margin: 15px 15px 30px 15px;
    text-align: center;
    font-size: 24px;
  }
}
.property-photos {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
  .photos {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    .photo {
      position: relative;
      align-self: center;
    }
    .img-fluid {
      width: 100%;
      padding: 20px;
      @media(min-width: 768px) {
        max-width: 313px;
      }
    }
  }
}

/** LIGHTBOX MARKUP **/

.lightbox {
  /** Default lightbox to hidden */
  display: none;

  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}

.lightbox img {
  /** Pad the lightbox image */
  max-width: 100%;
  max-height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lightbox:target {
  /** Remove default browser outline */
  outline: none;

  /** Unhide lightbox **/
  display: block;
}