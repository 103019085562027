html {
  background-color: $MidTownTeal;
}
body {
  font-family: $MidTownFont;
  color: $MidTownBlue;
}
p {
  font-size: 22px;
  margin-bottom: 25px;
}
h2 {
  font-size: 46px;
  margin-bottom: 25px;
}
h3 {
  font-size: 28px;
}
ol {
  font-size: 22px;
}
.button {
  margin: 0 auto;
  background: $MidTownBlue;
  border-radius: 6px;
  padding: 8px 6px;
  color: #ffffff;
  display: inline-block;
  font-family: $MidTownFont;
  font-size: 18px;
  max-width: 200px;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $MidTownTeal;
  }
}

.full-width-image {
  display: flex;
  background: linear-gradient( rgba(39, 36, 96, 0.5), rgba(39, 36, 96, 0.30) ), url('/assets/img/Midtown-homes-full-width.jpg') no-repeat center center;
  background-size: cover;
  height: 500px;
  text-align: center;
  font-family: $MidTownHeaderFont;
  font-weight: normal;
  color: white;
  align-items: stretch;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      font-size: 52px;
      margin: 0 auto;
    }
    h3 {
      font-size: 28px;
      margin: 0 auto;
      font-family: $MidTownFont;
    }
    .button {
      margin: 0 auto;
      background: #15506a;
      background: -webkit-linear-gradient(bottom, #03273a,#15506a);
      background: linear-gradient(top, #03273a, #15506a);
      border-radius: 4px;
      padding: 8px 20px;
      color: #ffffff;
      display: inline-block;
      font-family: $MidTownFont;
      font-size: 25px;
      text-align: center;
      max-width: 200px;
      align-self: center;
      &:hover, &:active, &:focus {
        text-decoration: none;
        color: $MidTownTeal;
      }
    }
  }
}
.navbar-middle {
  display: flex;
  justify-content: center;
  border-radius: 0px;
  border-bottom: $MidTownBlue solid 1.25px;
  margin-bottom: 0px;
  .nav-pills {
    text-align: center;
    display: flex;
    flex: 1 1 auto;
    max-width: 990px;
    justify-content: center;
    flex-wrap: wrap;
    li {
      font-size: 19px;
      float: none;
    }
    a {
      color: $MidTownBlue;
      padding: 10px 14px;
    }
  }
}
.nav>li>a:focus, .nav>li>a:hover {
  background-color: $MidTownBlue;
  color: $MidTownTeal;
}

.intro {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 25px;
  h2 {
    font-size: 48px;
  }
}
#blue-section {
  background-color: $MidTownBlue;
  color: white;
  padding-top: 30px;
  padding-bottom: 45px;
  h2 {
    color: $MidTownTeal;
  }
  ul {
    font-size: 22px;
    padding-left: 50px;
    clear: both;
    li {
      display: block;
    }
    li:before {
      /*Using a Bootstrap glyphicon as the bullet point*/
      content: "\e080";
      font-family: 'Glyphicons Halflings';
      font-size: 16px;
      float: left;
      margin-top: 4px;
      margin-left: -27px;
      margin-right: 10px;
      color: $MidTownTeal;
    }
  }
}
.img-fluid {
  width: 100%;
}
#available-properties-section {
  background-color: rgb(230,230,230);
}
.available-properties {
  text-align: center;
  //margin-top: 25px;
  //margin-bottom: 35px;
  h2 {
    margin-bottom: 20px;
  }
  .properties {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media(min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .prop {
      margin-bottom: 20px;
      @media(min-width: 768px) {
        width: 31%;
      }
      .image {
        margin: 0 auto;
        max-width: 350px;
      }
      h3 {
        font-size: 34px;
        color: $MidTownTeal;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
}
footer {
  font-size: 22px;
  color: $MidTownBlue;
  background-color: $MidTownTeal;
  .footer-container{
    padding-top: 40px;
    padding-bottom: 40px;
    .col-sm-4 {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      img {
        width: 100%;
        height: auto;
        max-width: 60px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .col-sm-8 {
      text-align: center;
      a {
        color: white;
      }
      a:hover {
        text-decoration: none;
        color: $MidTownBlue;
      }
    }
  }
}

@media (min-width: 768px) {
  h2 {
    font-size: 52px;
    letter-spacing: 1px;
  }
  .full-width-image {
    .title {
      h1 {
        font-size: 62px;
      }
      h3 {
        font-size: 36px;
      }
    }
  }
  .navbar-middle {
    .nav-pills {
      justify-content: center;
      li {
        font-size: 19px;
      }
    }
  }
  .intro.container {
    h2 {
      font-size: 58px;
      letter-spacing: 2px;
    }
  }
  .available-properties .properties .prop .image {
    margin: 30px 10px 10px 10px;
  }
}

@media(min-width: 992px) {
  .full-width-image {
    .title {
      h1 {
        font-size: 66px;
        letter-spacing: 2px;
      }
      h3 {
        padding-left: 125px;
        padding-right: 125px;
      }
    }
  }
  .navbar-middle .nav-pills li {
    font-size: 22px;
  }
  div.intro.container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media(min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}